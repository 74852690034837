import Home from "./Pages/Home";
import TechnicalSafety from "./Pages/TechnicalSafety";
import Risk from "./Pages/Risk";
import Design from "./Pages/Design";
import LPDS from "./Pages/LPDS";
import Contact from "./Pages/Contact";
import { HashRouter as Router, Routes } from "react-router-dom";
import { Route } from "react-router-dom";
import About from "./Pages/About";

export const hashPaths = [
  "/",
  "/techSafety",
  "/riskAandM",
  "/design",
  "LPDS",
  "/contact",
  "/about",
];
const RouterFunc = () => {
  return (
    <Router>
      <Routes>
        <Route path={hashPaths[0]} element={<Home />} />
        <Route path={hashPaths[1]} element={<TechnicalSafety />} />
        <Route path={hashPaths[2]} element={<Risk />} />
        <Route path={hashPaths[3]} element={<Design />} />
        <Route path={hashPaths[4]} element={<LPDS />} />
        <Route path={hashPaths[5]} element={<Contact />} />
        <Route path={hashPaths[6]} element={<About />} />
      </Routes>
    </Router>
  );
};

export default RouterFunc;
