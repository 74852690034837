import "./Home.css";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import loss from "../Images/loss.jpg";

function LPDS() {
  return (
    <div className="Service">
      <Header />
      <h1 className="service-title">Environmental Design Services</h1>
      <div className="service-body">
        <div className="service-text">
          <p>
            TDS Consultancy is also capable of delivering the following LPD
            services:
          </p>
          <li>Fire &amp; Gas Design</li>
          <li>Fire Water and Foam System Design</li>
          <li>Firewater Network Hydraulic Analysis</li>
          <li>Active and Passive Fire Protection Design</li>
          <li>Safety and Lifesaving Equipment (Offshore) Layout</li>
          <li>Access &amp; Egress, Evacuation, Escape and Rescue (EER)</li>
          <li>Survivability Analysis of Emergency Systems</li>
          <li>FEHA/FERA</li>
        </div>

        <img src={loss} alt="hazard" className="service-image" />
      </div>

      <Footer />
    </div>
  );
}

export default LPDS;
