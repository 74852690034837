import "./Home.css";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import workshop from "../Images/workshop.jpg";

function Design() {
  return (
    <div className="Service">
      <Header />
      <h1 className="service-title">Environmental Design Services</h1>
      <div className="service-body">
        <div className="service-text">
          <p>
            TDS Consultancy provides its customers with Integrated Environmental
            Design services for design and Operational Assets.
          </p>
          <ul>
            <li>Risk Assessment</li>
            <li>Dispersion Modeling and Analysis (for COMAH and QRA inputs)</li>
            <li>Waste and Pollution Support</li>
            <li>Radiation Assessment and Protection</li>
            <li>Environmental Impact Assessment Support</li>
            <li>Noise and Vibration Asessment Support</li>
            <li>Environment Identified Studies (ENVID)</li>
          </ul>
        </div>

        <img src={workshop} alt="hazard" className="service-image" />
      </div>

      <Footer />
    </div>
  );
}

export default Design;
