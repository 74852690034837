import Header from "../Components/Header";
import Footer from "../Components/Footer";
import "./About.css";

function About() {
  return (
    <div className="About">
      <Header />
      <h1 className="pageTitle">About</h1>
      <div className="content">
        <div className="contentText">
          <p>
            TDS Consultancy Services Ltd offer a full portfolio of Process
            Safety, Technical Safety, Design HSE &amp; Loss Prevention
            Consulting Services for a wide range of industriesl Oil &amp; Gas
            (Onshore and Offshore), Energy Industry, Petrochemicals, Refineries,
            Chemicals, Pharmaceuticals and all other high hazard industries. TDS
            Consultancy Services Ltd has strong experience in safety,
            environment and risk assessment studies across high risk industrial
            sectos. The services are focused on complying with applicable
            regulation, directive, codes &amp; standards, and meeting the
            fundamental demands of our customers through out various stages of
            the project lifecycle ranging from design through procurement and
            construction, commissioning, in service operations and
            decommissioning.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
