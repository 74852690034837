import "./Services.css";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import process from "../Images/oilrig.jpg";

function TechnicalSafety() {
  return (
    <div className="Service">
      <Header />
      <h1 className="service-title">
        Technical Safety/ Design HSE Engineering
      </h1>
      <div className="service-body">
        <div className="service-text">
          <p>
            TDS Consultancy provides Engineering support through design study
            through the different stages of the project ranging from FEED to
            EPCM/EPCIS/PMC. We have a wealth of experience in:
          </p>
          <ul>
            <li>Hazard Identification (HAZID)</li>
            <li>Hazard &amp; Operability Study (HAZOP)</li>
            <li>What-if, FTA/ETA and RCA Studies</li>
            <li>
              Preliminary Hazard Analysis (PHA) and Process Hazard Review (PHR)
            </li>
            <li>Hazardous Area Classification</li>
            <li>Fire &amp; Explosion Risk Analysis</li>
            <li>Plant &amp; Facility Layout Evalution</li>
            <li>Flare Reduction</li>
          </ul>
        </div>
        <img src={process} alt="process" className="service-image" />
      </div>
      <Footer />
    </div>
  );
}

export default TechnicalSafety;
