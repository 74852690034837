import logo from "../Images/TDS.jpg";
import "./Header.css";
import Dropdown from "./Dropdown";
import { useNavigate } from "react-router";

export const paths = ["/", "/#/contact", "/#/about"];

const baseUrl = "http://localhost:3000";

function Header() {
  const navigate = useNavigate();

  function redirect() {
    navigate("/");
  }

  if (window.innerWidth < 500) {
    return (
      <header>
        <img src={logo} alt="Logo" className="logo" onClick={redirect} />
        <Dropdown />
      </header>
    );
  } else {
    return (
      <header>
        <a href={paths[0]}>
          <img src={logo} alt="Logo" className="logo" />
        </a>

        <div className="headerButtons">
          <a
            href={paths[0]}
            className={window.location.href === paths[0] ? "current" : ""}
          >
            HOME
          </a>
          <a
            href={paths[1]}
            className={
              window.location.href === baseUrl + paths[1] ? "current" : ""
            }
          >
            CONTACT
          </a>
          <a
            href={paths[2]}
            className={
              window.location.href === baseUrl + paths[2] ? "current" : ""
            }
          >
            ABOUT
          </a>
        </div>
      </header>
    );
  }
}

export default Header;
