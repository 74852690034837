import "./Services.css";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import hazard from "../Images/hazard.jpg";

function Risk() {
  return (
    <div className="Service">
      <Header />
      <h1 className="service-title">Risk Assessment and Management</h1>
      <div className="service-body">
        <div className="service-text">
          <p>
            TDS Consultancy has expertise in carrying out Risk Assessment and
            providing Risk Management Services for high hazard environments such
            as Oil and Gas upstream facilities. Through an indepth analysis, our
            consultancy services identifies all hazards present, evalutates all
            the associated risks and devises a plan to apply measures to control
            the meet the Regulations and minimising the risks to 'As Low As
            Reasonably Practicable'. The services we provide include: 
          </p>
          <ul>
            <li>BAT and ALARP Assessments</li>
            <li>Emergency Response Planning</li>
            <li>Pipeline Hazard and Risk Analysis</li>
            <li>Safety Critical Elements &amp; Performance Standards</li>
            <li>Quantative Risk Analysis</li>
            <li>HSE (Safety) Studies</li>
            <li>Frequency, Consequence &amp; Impact Assessment</li>
            <li>
              Layer of Protection Analysis (LOPA) &amp; Safety Integrity Level
              (SIL) Analysis, Selection &amp; Verification
            </li>
          </ul>
        </div>

        <img src={hazard} alt="hazard" className="service-image" />
      </div>

      <Footer />
    </div>
  );
}

export default Risk;
