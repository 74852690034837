import { paths } from "../Components/Header";
import logo from "../Images/TDS.jpg";
import linkedin from "../Images/linkedin.png";
import "../Components/Footer.css";

function Footer() {
  return (
    <footer className="Footer">
      <div>
        <img src={logo} alt="logo" className="footerLogo" />
      </div>
      <div className="nameInfo">
        <h1>TDS Consultancy Ltd.</h1>
        <h2>T: +447913034731</h2>
        <h2>E: engrsarfraz@yahoo.com</h2>
        <a href="https://uk.linkedin.com/in/sarfrazali">
          <img src={linkedin} alt="linkedinLogo" className="footerlinkedin" />
        </a>
      </div>
      <div className="pageLinks">
        <a href={paths[0]}>Home</a>
        <a href={paths[1]}>Contact</a>
        <a href={paths[2]}>About</a>
      </div>
    </footer>
  );
}

export default Footer;
