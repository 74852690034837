import "./Contact.css";
import Header from "../Components/Header";
import phone from "../Images/phone.png";
import email from "../Images/email6.png";
import linkedin from "../Images/linkedin.png";
import address from "../Images/address.png";

function Contact() {
  return (
    <div className="Contact">
      <div className="Contact">
        <Header />
        <h1 className="pageTitle">Contact</h1>
        <p className="contacttext">
          To find out more about how we can help you achieve your goal please
          get in touch and we will answer all your questions.
        </p>
        <div className="contactDiv">
          <div className="contactRow">
            <img src={email} alt="icon" className="contactIcon" />
            <p className="value">engrsarfraz@yahoo.com</p>
          </div>

          <div className="contactRow">
            <img src={phone} alt="icon" className="contactIcon" />
            <p className="value">+447913034731 (UK)</p>
          </div>

          <div className="contactRow">
            <img src={linkedin} alt="icon" className="contactIcon" />
            <a
              className="logoLinks"
              href="https://uk.linkedin.com/in/sarfrazali"
            >
              <button className="linkedinButton">LinkedIn</button>
            </a>
          </div>

          <div className="contactRow">
            <img src={address} alt="address" className="contactIcon" />
            <p className="value">
              Unit 8-12, Dock Oddices, Surrey, Quays Road, London, SE16 2XU
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
