import "./Home.css";
import "../Components/Header";
import Header from "../Components/Header";
import rig from "../Images/oilrig.jpg";
import hazard from "../Images/hazard.jpg";
import workshop from "../Images/workshop.jpg";
import loss from "../Images/loss.jpg";
import Footer from "../Components/Footer";

function Home() {
  return (
    <div className="Home">
      <Header />
      <h1 className="workHeading">Our Services</h1>
      <div className="servicesHome">
        <div className="service">
          <div>
            <a href="/#/techSafety">
              <img src={rig} alt="rig" className="serviceImg" />
            </a>
          </div>
          <h1 className="serviceName">
            Technical Safety and Design HSE Engineering
          </h1>
        </div>
        <div className="service">
          <div>
            <a href="/#/riskAandM">
              <img src={workshop} alt="hazard" className="serviceImg" />
            </a>
          </div>
          <h1 className="serviceName">Risk Assessment and Management</h1>
        </div>
        <div className="service">
          <div>
            <a href="/#/design">
              <img src={hazard} alt="workshop" className="serviceImg" />
            </a>
          </div>

          <h1 className="serviceName">Environmental Design Services</h1>
        </div>
        <div className="service">
          <div>
            {" "}
            <a href="/#/LPDS">
              <img src={loss} alt="loss" className="serviceImg" />
            </a>
          </div>
          <h1 className="serviceName">Loss Prevention Design Services</h1>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
